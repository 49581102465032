import React, { Fragment, useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Testimonial from "./slider/testimonial";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "sal.js/dist/sal.css";
// import BlogSlider from "./slider/BlogSlider";
import Promo from "./slider/Promo";
import Location from "./sales-page/Location";
import axios from "axios";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
const assets = '/assets';

const Career = ({ vps = {}, index }) => {

    const [aboutuss, setAboutuss] = useState([]);

    useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/aboutus")
            .then((response) => {
                setAboutuss(response.data[0])
            })
            .catch((error) => {
                console.error("Error fetching RDP data:", error);
            });
    }, [])

    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);
    const [isExpanded4, setIsExpanded4] = useState(false);


    return (
        <Fragment>
            <Header />
            {/* main top */}

            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Careers at DigiRDP</h3>
                                    <p className="description b1">Step into a world of innovation and growth. At DigiRDP, we’re looking for passionate individuals to <br /> join our team and help revolutionize the cloud and VPS industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}

                {/* <!-- End Pricing Style-2  --> */}

                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">
                        <Promo />

                        {/* <!-- Start Tab__Style--one Area  --> */}
                        <div className="rainbow-service-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center pb--60">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Careers at DigiRDP</span>
                                            </h4>
                                            <h2 className="title mb--0">Empower Your Future with Us</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row--30 align-items-center">
                                    <div className="col-lg-6">
                                        <p>Step into a world of innovation and growth. At DigiRDP, we’re looking for passionate individuals to join our team and help revolutionize the cloud and VPS industry.</p>
                                        <h5>Why Join Us?</h5>
                                        <ol>
                                            <li className="ol-li">Innovative Work Culture</li>
                                            <p>We collect and store information that you provide to us, such as your name, email address, and payment information. We may also collect information about your use of our service, such as IP addresses, device identifiers, and usage data.</p>
                                            <li className="ol-li">Professional Growth</li>
                                            <p>Benefit from training programs, certifications, and mentorship opportunities to enhance your skills.</p>
                                            <li className="ol-li">Global Opportunities</li>
                                            <p>Become part of a company with a global vision, serving clients from all over the world.</p>
                                        </ol>
                                    </div>
                                    <div className="col-lg-6">
                                        <div>
                                            <img src={`${assets}/images/added/career.svg`} alt="Brand" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Brand Area --> */}
                        <div className="rainbow-brand-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title rating-title text-center sal-animate" data-sal="slide-up"
                                            data-sal-duration="700" data-sal-delay="100">
                                            <p className="b1 mb--0 small-title">truest 800,000+ HIGHLY PRODUCTIVE Company</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt--10">
                                        <div className="brand-slider-container">
                                            <ul className="brand-list brand-style-2 slider-brand">
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/1d.png`} alt="Brand" /></a></li>
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/2d.svg`} alt="Brand" /></a></li>
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/3d.png`} alt="Brand" /></a></li>
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/4d.webp`} alt="Brand" /></a></li>
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/5d.png`} alt="Brand" /></a></li>
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/6d.png`} alt="Brand" /></a></li>
                                                <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/7d.svg`} alt="Brand" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Open Positions</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 mt--60">
                                        <ul class="job-list">
                                            <li class="job-preview">
                                                <div className="div-career">
                                                    <div class="content float-left">
                                                        <h5>Full-Time, Day shift : 9:30 AM - 6:30 PM</h5>
                                                        <h4 class="job-title">Relationship Manager <p> Posted by Realty Assistant</p></h4>
                                                        
                                                    </div>
                                                    <div className="header-btn">
                                                        <a className="rainbow-gradient-btn" rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/digirdp-llc/jobs/">
                                                            <span>Apply</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div className="job-description">
                                                    <h5 
                                                        className="company" 
                                                        onClick={() => setIsExpanded1(!isExpanded1)} 
                                                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                                    >
                                                        Job Description
                                                        <span style={{ marginLeft: "8px" }}>
                                                            {isExpanded1 ? <FaChevronUp /> : <FaChevronDown />}
                                                        </span>
                                                    </h5>
                                                    {isExpanded1 && (
                                                        <p>
                                                            We are looking for high-performing Sales managers to help us meet our customer
                                                            acquisition and revenue growth targets by keeping our company competitive and innovative. You will
                                                            be responsible for maximizing our sales team's potential, crafting sales plans, and justifying those
                                                            plans to the upper management.
                                                        </p>
                                                    )}
                                                </div>
                                            </li>
                                            <li class="job-preview">
                                                <div className="div-career">
                                                    <div class="content float-left">
                                                        <h5>Full-Time, Day shift : 9:30 AM - 6:30 PM</h5>
                                                        <h4 class="job-title">Relationship Manager <p> Posted by Realty Assistant</p></h4>
                                                        
                                                    </div>
                                                    <div className="header-btn">
                                                        <a className="rainbow-gradient-btn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/digirdp-llc/jobs/">
                                                            <span>Apply</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div className="job-description">
                                                    <h5 
                                                        className="company" 
                                                        onClick={() => setIsExpanded2(!isExpanded2)}
                                                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                                    >
                                                        Job Description
                                                        <span style={{ marginLeft: "8px" }}>
                                                            {isExpanded2 ? <FaChevronUp /> : <FaChevronDown />}
                                                        </span>
                                                    </h5>
                                                    {isExpanded2 && (
                                                        <p>
                                                            We are looking for high-performing Sales managers to help us meet our customer
                                                            acquisition and revenue growth targets by keeping our company competitive and innovative. You will
                                                            be responsible for maximizing our sales team's potential, crafting sales plans, and justifying those
                                                            plans to the upper management.
                                                        </p>
                                                    )}
                                                </div>
                                            </li>
                                            <li class="job-preview">
                                                <div className="div-career">
                                                    <div class="content float-left">
                                                        <h5>Full-Time, Day shift : 9:30 AM - 6:30 PM</h5>
                                                        <h4 class="job-title">Relationship Manager <p> Posted by Realty Assistant</p></h4>
                                                        
                                                    </div>
                                                    <div className="header-btn">
                                                        <a className="rainbow-gradient-btn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/digirdp-llc/jobs/">
                                                            <span>Apply</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div className="job-description">
                                                    <h5 
                                                        className="company" 
                                                        onClick={() => setIsExpanded3(!isExpanded3)}
                                                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                                    >
                                                        Job Description
                                                        <span style={{ marginLeft: "8px" }}>
                                                            {isExpanded3 ? <FaChevronUp /> : <FaChevronDown />}
                                                        </span>
                                                    </h5>
                                                    {isExpanded3 && (
                                                        <p>
                                                            We are looking for high-performing Sales managers to help us meet our customer
                                                            acquisition and revenue growth targets by keeping our company competitive and innovative. You will
                                                            be responsible for maximizing our sales team's potential, crafting sales plans, and justifying those
                                                            plans to the upper management.
                                                        </p>
                                                    )}
                                                </div>
                                            </li>
                                            <li class="job-preview">
                                                <div className="div-career">
                                                    <div class="content float-left">
                                                        <h5>Full-Time, Day shift : 9:30 AM - 6:30 PM</h5>
                                                        <h4 class="job-title">Relationship Manager <p> Posted by Realty Assistant</p></h4>
                                                        
                                                    </div>
                                                    <div className="header-btn">
                                                        <a className="rainbow-gradient-btn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/digirdp-llc/jobs/">
                                                            <span>Apply</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div className="job-description">
                                                    <h5 
                                                        className="company" 
                                                        onClick={() => setIsExpanded4(!isExpanded4)}
                                                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                                    >
                                                        Job Description
                                                        <span style={{ marginLeft: "8px" }}>
                                                            {isExpanded4 ? <FaChevronUp /> : <FaChevronDown />}
                                                        </span>
                                                    </h5>
                                                    {isExpanded4 && (
                                                        <p>
                                                            We are looking for high-performing Sales managers to help us meet our customer
                                                            acquisition and revenue growth targets by keeping our company competitive and innovative. You will
                                                            be responsible for maximizing our sales team's potential, crafting sales plans, and justifying those
                                                            plans to the upper management.
                                                        </p>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>


                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Become a Reseller Today  </h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Partner with us and transform the way you do business. As a reseller, you’ll gain access to top-tier products, tailored support, and a platform to maximize your success. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/reseller-program">Grow with DigiRDP </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                    </div>
                </div>
            </div>
            {/* <!-- Start Pricing Area  --> */}

            <Location />


            <Footer />

        </Fragment>
    );
}

export default Career;
