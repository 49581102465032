import React, { Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
const assets = '/assets';

function Footer() {
    const [v_p_s, set_Vps] = useState([]);
    const [rdps, set_Rdps] = useState([]);
    const [rdp_location, set_Rdp_location] = useState([]);
    const [dedicated, set_Dedicated] = useState([]);
    const [config, set_config] = useState([]);
    const [logo, set_logo] = useState([]);

     useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/logo")
            .then((response) => {
                set_logo(response.data)
            })
            .catch((error) => {
                console.error("Error logo data:", error);
            });
    }, [])  

    useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/configurations")
            .then((response) => {
                set_config(response.data)
            })
            .catch((error) => {
                console.error("Error fetching RDP data:", error);
            });
    }, [])

    useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/windows_rdps")
            .then((response) => {
                set_Rdps(response.data[1])
            })
            .catch((error) => {
                console.error("Error fetching RDP data:", error);
            });
    }, [])

    useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/cloud_vps")
            .then((response) => {
                set_Vps(response.data)
            })
            .catch((error) => {
                console.error("Error fetching VPS data:", error);
            });
    }, [])

    useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/dedicated_server")
            .then((response) => {
                set_Dedicated(response.data)
            })
            .catch((error) => {
                console.error("Error fetching Dedicated data:", error);
            });
    }, [])

    useEffect(() => {
        axios.get("https://admin.digirdp.chaipost.co.in/api/r_d_p_by_locations")
            .then((response) => {
                set_Rdp_location(response.data)
            })
            .catch((error) => {
                console.error("Error fetching VPS data:", error);
            });
    }, [])


    useEffect(() => {
        // backToTopInit functionality
        const backToTopInit = () => {
            const scrollTop = $('.rainbow-back-top');

            // Show or hide the back-to-top button based on scroll position
            $(window).scroll(function () {
                const topPos = $(this).scrollTop();
                if (topPos > 150) {
                    $(scrollTop).css('opacity', '1');
                } else {
                    $(scrollTop).css('opacity', '0');
                }
            });

            // Smooth scroll to the top when the button is clicked
            $(scrollTop).on('click', function () {
                $('html, body').animate(
                    {
                        scrollTop: 0,
                    },
                    300 // Adjust duration for smooth scrolling
                );
                return false;
            });
        };

        // Initialize back-to-top functionality
        backToTopInit();
    }, []); // Run only once on component mount

    useEffect(() => {
        const initProgressIndicator = () => {
            // Select the SVG path element
            const path = document.querySelector('.rbt-progress-parent path');
            const pathLength = path.getTotalLength();

            // Set up initial styles for the path
            path.style.transition = path.style.WebkitTransition = 'none';
            path.style.strokeDasharray = `${pathLength} ${pathLength}`;
            path.style.strokeDashoffset = pathLength;
            path.getBoundingClientRect(); // Trigger layout
            path.style.transition = path.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

            // Function to update the stroke-dashoffset on scroll
            const updateProgress = () => {
                const scrollTop = $(window).scrollTop();
                const docHeight = $(document).height();
                const winHeight = $(window).height();
                const scrollPercent = scrollTop / (docHeight - winHeight);
                path.style.strokeDashoffset = pathLength * (1 - scrollPercent);
            };

            // Initial call to set up the progress
            updateProgress();
            $(window).on('scroll', updateProgress);

            // Toggle back-to-top button visibility on scroll
            $(window).on('scroll', () => {
                if ($(window).scrollTop() > 50) {
                    $('.rbt-progress-parent').addClass('rbt-backto-top-active');
                } else {
                    $('.rbt-progress-parent').removeClass('rbt-backto-top-active');
                }
            });

            // Scroll to top on button click
            $('.rbt-progress-parent').on('click', (event) => {
                event.preventDefault();
                $('html, body').animate({ scrollTop: 0 }, 550);
            });
        };

        // Initialize the progress indicator
        initProgressIndicator();
    }, []); // Empty dependency array ensures the effect runs only once

   

    return (
        <Fragment>

           

            {/* <!-- Start Footer Area  --> */}
            <footer className="rainbow-footer footer-style-default footer-style-3 position-relative">
                <div className="footer-top">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="rainbow-footer-widget">
                                    <div className="logo">
                                        <a href="/">
                                            <img className="logo-light" src={`${logo.light_logo}`} alt="ChatBot Logo" />
                                            <img className="logo-dark" src={`${logo.logo_name}`} alt="ChatBot Logo" />
                                        </a>
                                    </div>
                                    <p className="b1 desc-text">A Mile watching RDP Provider</p>
                                    <div className="rainbow-footer-widget">
                                        <div className="widget-menu-bottom">
                                            <h4 className="title mb-0">Follow Us!</h4>
                                            <div className="inner mt-0">
                                                <ul className="footer-link link-hover d-flex">
                                                    <li className="li-icon"><a href="https://www.instagram.com/digirdp/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
                                                    <li className="li-icon"><a href="https://www.facebook.com/digirdpcom" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook" ></i></a></li>
                                                    <li className="li-icon"><a href="https://github.com/digirdpllc/" target="_blank" rel="noreferrer"><i className="fa-brands fa-github"></i></a></li>
                                                    <li className="li-icon"><a href="https://x.com/digirdp" target="_blank" rel="noreferrer"><i class="fa-brands fa-twitter"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rainbow-footer-widget">
                                    <div className="widget-menu-bottom">
                                        <h4 className="title">Windows RDP</h4>
                                        <div className="inner has-show-more">
                                            <ul className="footer-link link-hover list-style--1 has-show-more-inner-content">
                                                {rdps
                                                .filter((rdp) => rdp.show_in_header === 1) 
                                                .map((rdp, index) => (
                                                    <li key={index}><Link to={`/rdp-plan/${rdp.url_text}`}>{rdp.name}</Link></li>
                                                ))}
                                                {/* <li><a href="/">Canada Admin RD</a></li>
                                                <li><a href="/">AMD EPYC Storage RDP</a></li>
                                                <li><a href="/">Indian AMD EPYC RDP</a></li>
                                                <li><a href="/">Singapore Private RDP</a></li>
                                                <li><a href="/">Ryzen Private RDP</a></li>
                                                <li><a href="/">Buy Bluestacks RDP</a></li>
                                                <li><a href="/">Indian Admin RDP</a></li>
                                                <li><a href="/">USA Admin RD</a></li>
                                                <li><a href="/">Budget/Europe Admin RDP</a></li>
                                                <li><a href="/">Streaming RD</a></li>
                                                <li><a href="/">Encoding RDP</a></li>
                                                <li><a href="/">USA Shared RDP</a></li>
                                                <li><a href="/">India Residential RDP (Static)</a></li>
                                                <li><a href="/">UK Real Residential RDP</a></li>
                                                <li><a href="/">US Real Residential RDP</a></li>
                                                <li><a href="/">US Residential/Dating RDP (Static)</a></li>
                                                <li><a href="/">UK Residential RDP (Static)</a></li> */}
                                            </ul>
                                            <div className="rbt-show-more-btn">Show More</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rainbow-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">Dedicated Server</h4>
                                        <div className="inner has-show-more">
                                            <ul className="footer-link link-hover list-style--1 has-show-more-inner-content">
                                                {dedicated
                                                .filter((dedicate) => dedicate.show_in_header === 1) 
                                                .map((dedicate, index) => (
                                                    <li><Link to={`/dedicated-server/${dedicate.url_text}`}>{dedicate.name}</Link></li>
                                                ))}
                                                {/* <li><a href="/">Australia Dedicated Server</a></li>
                                                <li><a href="/">Storage Dedicated Server</a></li>
                                                <li><a href="/">Dallas Budget Servers</a></li>
                                                <li><a href="/">Instant Dedicated Server</a></li>
                                                <li><a href="/">Los Angeles Dedicated Server</a></li>
                                                <li><a href="/">Cheap Germany Dedicated Server</a></li>
                                                <li><a href="/">Cheap France Dedicated server</a></li>
                                                <li><a href="/">USA Dedicated Server</a></li>
                                                <li><a href="/">Indian Dedicated Server</a></li> */}
                                            </ul>
                                            <div className="rbt-show-more-btn">Show More</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rainbow-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">Cloud VPS</h4>
                                        <div className="inner has-show-more">
                                            <ul className="footer-link link-hover list-style--1 has-show-more-inner-content">
                                                {v_p_s
                                                .filter((vps) => vps.show_in_header === 1) 
                                                .map((vps, index) => (
                                                    <li><Link to={`/cloud-vps/${vps.url_text}`}>{vps.name}</Link></li>
                                                ))}
                                                {/* <li><a href="/">Netherlands Cloud VPS</a></li>
                                                <li><a href="/">Canada Cloud VPS</a></li>
                                                <li><a href="/">AMD EPYC Storage VPS</a></li>
                                                <li><a href="/">UK Cloud VPS</a></li>
                                                <li><a href="/">Indian AMD EPYC VPS Hosting</a></li>
                                                <li><a href="/">Miami Cloud VPS</a></li>
                                                <li><a href="/">US NVMe VPS</a></li>
                                                <li><a href="/">New York Cloud VPS</a></li>
                                                <li><a href="/">Dallas Cloud VPS</a></li>
                                                <li><a href="/">Indian Cloud VPS</a></li>
                                                <li><a href="/">Singapore Cloud VPS</a></li>
                                                <li><a href="/">SSD VPS Hosting</a></li>
                                                <li><a href="/">Linux VPS Hosting</a></li> */}
                                            </ul>
                                            <div className="rbt-show-more-btn">Show More</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rainbow-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">Private RDP</h4>
                                        <div className="inner has-show-more">
                                            <ul className="footer-link link-hover list-style--1 has-show-more-inner-content">
                                                {rdp_location
                                                .filter((rdp_loc) => rdp_loc.show_in_header === 1) 
                                                .map((rdp_loc, index) => (
                                                    <li><Link to={`/private_rdp/${rdp_loc.url_text}`}>{rdp_loc.name}</Link></li>

                                                ))}
                                                {/* <li><a href="/">Miami Private RDP</a></li>
                                                <li><a href="/">Amazon Mturk RDP</a></li>
                                                <li><a href="/">New York Ryzen RDP</a></li>
                                                <li><a href="/">Dallas Ryzen RDP</a></li>
                                                <li><a href="/">Singapore Ryzen RDP</a></li>
                                                <li><a href="/">Germany Ryzen RDP</a></li>
                                                <li><a href="/">Netherlands Ryzen RDP</a></li>
                                                <li><a href="/">UK Ryzen RDP</a></li>
                                                <li><a href="/">Cheap UK RDP</a></li>
                                                <li><a href="/">Netherlands RDP</a></li>
                                                <li><a href="/">Residential RDP</a></li>
                                                <li><a href="/">Dating RDP</a></li>
                                                <li><a href="/">Buy USA RDP</a></li> */}
                                            </ul>
                                            <div className="rbt-show-more-btn">Show More</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer Area  -->
            <!-- Start Copy Right Area  --> */}
            <div className="copyright-area copyright-style-one">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                            <div className="copyright-left">
                                <ul className="ft-menu link-hover">
                                    <li>
                                        <Link to="/legal">Legal</Link>
                                    </li>
                                    <li>
                                        <Link to="/about">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/career">Career</Link>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" r href="https://manage.digirdp.com/index.php?m=abusemanagerpro">Report Abuse</a>
                                    </li>
                                    <li className='d-xxl-none d-xl-block d-lg-block d-block'>
                                        <p className="copyright-text">Copyright © 2019-2024 <a href="/" className="btn-read-more"><span> DigiRDP </span></a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                         <div className="col-lg-6 col-md-4 col-sm-12 col-12 d-xxl-block d-xl-none d-lg-none d-none">
                            <div className="copyright-right text-center text-lg-end">
                                <p className="copyright-text">Copyright © 2019-2024 <a href="/" className="btn-read-more"><span>DigiRDP </span></a></p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            {/* <!-- End Copy Right Area  --> */}
            <div className="rbt-progress-parent rainbow-back-top" style={{ opacity: 0 }}>
                <svg className="rbt-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </Fragment>
    )
}

export default Footer
