import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../slider/testimonial";
import BlogSlider from "../slider/BlogSlider";
import axios from "axios";
import { useParams } from "react-router-dom";
import Promo from "../slider/Promo";
import Location from "../sales-page/Location";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import CoupenCode from "../CoupenCode";
const assets = "/assets";

const Cloudvps = () => {
  const [cloudvpsplans, setCloudVpsPlans] = useState([]);
  const [cloudvps, setCloudVps] = useState([]);
  const { url_text } = useParams();
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [buttonText, setButtonText] = useState("Copy");
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    const fetchslider = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://admin.digirdp.chaipost.co.in/api/slider`
        );
        console.log("Slider API Response:", response.data[0]);
        setSliders(response.data);
      } catch (error) {
        console.error("Error fetching cloud vps plan data:", error);
        //   setCloudVpsPlan(null);
        //   setCloudVps(null);
      } finally {
        setLoading(false);
      }
    };

    fetchslider();
    // console.log("cloudvpsplan",cloudvpsplans);
    // console.log("cloudvps",cloudvps);
  }, []);
  const handleCopyCoupon = (couponCode) => {
    navigator.clipboard
      .writeText(couponCode) // Copy the coupon code
      .then(() => {
        setButtonText("Copied!"); // Change button text to 'Copied!'
        // setTimeout(() => {
        //     setButtonText("Copy"); // Reset button text after 3 seconds
        // }, 3000);
      })
      .catch(() => {
        console.error("Failed to copy the coupon code");
      });
  };
  console.log(url_text);

  useEffect(() => {
    const fetchcloudvpsplan = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://admin.digirdp.chaipost.co.in/api/cloud_vps_plan/${url_text}`
        );
        console.log("cloud vps API Response:", response.data[0]);
        console.log("cloud vps plan API Response:", response.data[1]);

        setCloudVps(response.data[0]);
        setCloudVpsPlans(response.data[1]);
      } catch (error) {
        console.error("Error fetching cloud vps plan data:", error);
        //   setCloudVpsPlan(null);
        //   setCloudVps(null);
      } finally {
        setLoading(false);
      }
    };

    if (url_text) {
      fetchcloudvpsplan();
      // console.log("cloudvpsplan",cloudvpsplans);
      // console.log("cloudvps",cloudvps);
    }
  }, [url_text]);

  const [btnTexts, setBtnTexts] = useState({});
  const [couponCode, setCouponCode] = useState(
    cloudvps?.couponCode || "STEALDEAL20"
  );

  // Update couponCode whenever cloudvps.couponCode changes, ensuring cloudvps is defined
  useEffect(() => {
    setCouponCode(cloudvps?.couponCode || "STEALDEAL20");
  }, [cloudvps?.couponCode]);

  // Handle copying of coupon code
  const handleCopy = (couponCode, index) => {
    const codeToCopy = couponCode || "STEALDEAL20"; // Use provided code or default

    navigator.clipboard
      .writeText(codeToCopy) // Copy the coupon code
      .then(() => {
        setBtnTexts((prev) => ({ ...prev, [index]: "COPIED!" }));
        setTimeout(() => {
          setBtnTexts((prev) => ({ ...prev, [index]: "Copy Code" }));
        }, 3000);
      })
      .catch(() => {
        console.error("Failed to copy the code");
      });
  };

  return (
    <Fragment>
      <Helmet>
        {/* Google Analytics Script */}
        
        {/* Meta description or keywords */}
        <meta name="keywords" content={cloudvps.keywords} />
      </Helmet>
      <Header />

      {/* <!-- Start Pricing Area  --> */}
      <div className="main-content">
        {/* <!-- Start Breadcarumb area  --> */}
        <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner text-center">
                  <h3 className="title h3">{cloudvps && cloudvps.name}</h3>
                  <p className="description b1">{cloudvps.description}</p>
                  <ul className="page-list">
                    <li className="rainbow-breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="rainbow-breadcrumb-item">
                      <Link to="/cloud-vps">Cloud VPS</Link>
                    </li>
                    <li className="rainbow-breadcrumb-item active">
                      {cloudvps && cloudvps.name}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
        <div className="rainbow-pricing-area rainbow-section-gap">
          <div className="container-fluid">
            {sliders.filter((slider) => slider.page_id === 3).length > 0 && (
              <div className="rainbow-blog-area">
                <div className="container-fluid">
                  <div className="col-lg-12">
                    <div
                      className="section-title text-center"
                      data-sal-duration="400"
                      data-sal-delay="150"
                    >
                      <h4 className="subtitle">
                        <span className="theme-gradient">Our Offers</span>
                      </h4>
                    </div>
                  </div>
                  <div className="burger-slider">
                    <div className="slider-wrapper row">
                      {sliders
                        .filter((slider) => slider.page_id === 3)
                        .map((slider) => (
                          <div
                            className="slide col-lg-3 col-md-6 col-sm-12"
                            key={slider.id}
                          >
                            <div className="img-container">
                              <img
                                src={`${slider.slider_image}`}
                                alt=""
                                className="burger-image"
                              />
                              <div className="burger-info">
                                <div className="burger-title" title="">
                                  {slider.slider_details}
                                </div>
                                <div
                                  className="burger-description"
                                  offer_code=""
                                >
                                  {slider.slider_heading}
                                </div>
                                <button
                                  className="add-to-cart"
                                  onClick={handleCopyCoupon("#SAVE20")}
                                >
                                  {buttonText}
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <!-- Pricing Part --> */}
            <div className="wrapper rainbow-section-gap">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-lg-12">
                    <div
                      className="section-title text-center slide-up"
                      data-sal-duration="400"
                      data-sal-delay="150"
                    >
                      <h4 className="subtitle">
                        <span className="theme-gradient">Pricing</span>
                      </h4>
                      <h2 className="title w-600 mb--20">Our product</h2>
                      <p className="description b1">
                        Priced to fit your specific needs
                      </p>
                    </div>

                    <nav className="aiwave-tab">
                      <div
                        className="tab-btn-grp nav nav-tabs text-center justify-content-center"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="nav-month-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-month"
                          type="button"
                          role="tab"
                          aria-controls="nav-month"
                          aria-selected="false"
                        >
                          Monthly
                        </button>
                        <button
                          className="nav-link with-badge "
                          id="nav-year-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-year"
                          type="button"
                          role="tab"
                          aria-controls="nav-year"
                          aria-selected="true"
                        >
                          Yearly
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="row row--15 mt-5">
                  <div
                    className="tab-content p-0 bg-transparent border-0 border bg-light"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade active show"
                      id="nav-month"
                      role="tabpanel"
                      aria-labelledby="nav-month-tab"
                    >
                      <div
                        className="tab-content p-0 bg-transparent border-0 border bg-light"
                        id="nav-tabContent"
                      >
                        <div
                          className="tab-pane fade active show"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className="row row--15 mt_dec--40">
                            {cloudvpsplans.map((cloudvpsplan, index) => (
                              <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                <div className="rainbow-pricing style-aiwave ">
                                  <div className="pricing-table-inner">
                                    <div className="pricing-top">
                                      <div className="pricing-header">
                                        <h4 className="title color-var-one">
                                          {cloudvpsplan.name}
                                        </h4>
                                        <div className="pricing">
                                          <span className="price-text">
                                            ${cloudvpsplan.offer_price}{" "}
                                          </span>
                                          <span className="text d-flex">
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {cloudvpsplan.price}
                                            </span>
                                            /Per Month
                                          </span>
                                        </div>
                                      </div>
                                      <div className="pricing-footer">
                                        <Link
                                          className="btn-default"
                                          to={cloudvps.plan_url}
                                        >
                                          Buy Now
                                        </Link>
                                      </div>
                                      {cloudvpsplan.promocode ? (
                                        <div className="coupon-card">
                                          <div className="coupon-row">
                                            {/* Display the coupon code dynamically */}
                                            <span id="cpnCode">
                                              {cloudvpsplan.promocode}
                                            </span>

                                            {/* The Copy button */}
                                            <span
                                              id="cpnBtn"
                                              onClick={() =>
                                                handleCopy(
                                                  cloudvpsplan.promocode,
                                                  index
                                                )
                                              }
                                            >
                                              {btnTexts[index] || "Copy Code"}
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="pricing-body">
                                        <div className="features-section has-show-more">
                                          <h6>Features</h6>
                                          <ul className="list-style--1 has-show-more-inner-content">
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.users} Users
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.processor}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.cpu}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.traffic}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.os}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.ram} Ram
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              Bandwidth {cloudvpsplan.bandwidth}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              IP Address {cloudvpsplan.ip}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.drives}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.uptime} uptime
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.description}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.free}
                                            </li>
                                            {cloudvpsplan.a ? (
                                              <li>
                                                <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                                {cloudvpsplan.a}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                            {cloudvpsplan.b ? (
                                              <li>
                                                <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                                {cloudvpsplan.b}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                            {cloudvpsplan.c ? (
                                              <li>
                                                <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                                {cloudvpsplan.c}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                          </ul>
                                          <div className="rbt-show-more-btn">
                                            Show More
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {cloudvpsplan ? (
                                    <div className="feature-badge">
                                      {cloudvpsplan.tag}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade "
                      id="nav-year"
                      role="tabpanel"
                      aria-labelledby="nav-year-tab"
                    >
                      <div
                        className="tab-content p-0 bg-transparent border-0 border bg-light"
                        id="nav-tabContent"
                      >
                        <div
                          className="tab-pane fade active show"
                          id="nav-home1"
                          role="tabpanel"
                          aria-labelledby="nav-home1-tab"
                        >
                          {/* <h4 className="title w-600 mb--40 text-center">Explore to our smart Cloud VPS plans</h4> */}
                          <div className="row row--15 mt_dec--40">
                            {cloudvpsplans.map((cloudvpsplan, index) => (
                              <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                <div className="rainbow-pricing style-aiwave ">
                                  <div className="pricing-table-inner">
                                    <div className="pricing-top">
                                      <div className="pricing-header">
                                        <h4 className="title color-var-one">
                                          {cloudvpsplan.name}
                                        </h4>
                                        <div className="pricing">
                                          <span className="price-text">
                                            ${cloudvpsplan.price_annually}{" "}
                                          </span>
                                          <span className="text d-flex">
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {cloudvpsplan.price * 12}
                                            </span>
                                            /Per Year
                                          </span>
                                        </div>
                                      </div>
                                      <div className="pricing-footer">
                                        <Link
                                          className="btn-default"
                                          to={cloudvps.plan_url}
                                        >
                                          Buy Now
                                        </Link>
                                      </div>
                                      {cloudvpsplan.promocode_annually ? (
                                        <div className="coupon-card">
                                          <div className="coupon-row">
                                            {/* Display the coupon code dynamically */}
                                            <span id="cpnCode">
                                              {cloudvpsplan.promocode_annually}
                                            </span>

                                            {/* The Copy button */}
                                            <span
                                              id="cpnBtn"
                                              onClick={() =>
                                                handleCopy(
                                                  cloudvpsplan.promocode_annually,
                                                  index
                                                )
                                              }
                                            >
                                              {btnTexts[index] || "Copy Code"}
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="pricing-body">
                                        <div className="features-section has-show-more">
                                          <h6>Features</h6>
                                          <ul className="list-style--1 has-show-more-inner-content">
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.users} Users
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.processor}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.cpu}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.traffic}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.os}
                                            </li>
                                            <li>
                                              <i className="fa-regular fa-circle-check"></i>{" "}
                                              {cloudvpsplan.ram} Ram
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              Bandwidth {cloudvpsplan.bandwidth}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              IP Address {cloudvpsplan.ip}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.drives}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.uptime} uptime
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.description}
                                            </li>
                                            <li>
                                              <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                              {cloudvpsplan.free}
                                            </li>
                                            {cloudvpsplan.a ? (
                                              <li>
                                                <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                                {cloudvpsplan.a}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                            {cloudvpsplan.b ? (
                                              <li>
                                                <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                                {cloudvpsplan.b}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                            {cloudvpsplan.c ? (
                                              <li>
                                                <i className="fa-sharp fa-regular fa-minus-circle"></i>{" "}
                                                {cloudvpsplan.c}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                          </ul>
                                          <div className="rbt-show-more-btn">
                                            Show More
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {cloudvpsplan ? (
                                    <div className="feature-badge">
                                      {cloudvpsplan.tag}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Start Features Area --> */}
            <div className="rainbow-testimonial-area rainbow-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="section-title text-center"
                      data-sal-duration="400"
                      data-sal-delay="150"
                    >
                      <h4 className="subtitle">
                        <span className="theme-gradient">Features</span>
                      </h4>
                      <h2 className="title w-600 mb--20">
                        These features come <br /> standard in all of our plans
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Start Features Area --> */}
            <div className="rainbow-testimonial-area rainbow-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="section-title text-center sal-animate"
                      data-sal-duration="400"
                      data-sal-delay="150"
                    >
                      <h4 className="subtitle">
                        <span className="theme-gradient"></span>
                      </h4>
                      <h2 className="title w-600 mb--20">
                        Our Hosting Solutions{" "}
                      </h2>
                      <p>
                        Explore our tailored solutions with Windows RDP, Cloud
                        VPS, and Dedicated servers to find <br /> the perfect
                        fit for your application deployment needs. <br /> Get
                        the performance, reliability, and ease you deserve with
                        our expertly crafted plans{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12 mt--60">
                    <div className="advance-tab-button advance-tab-button-1 right-top">
                      <ul className="nav nav-tabs tab-button-list">
                        <li className="col-lg-3 nav-item" role="presentation">
                          <a href="/rdp-plan" className="nav-link tab-button ">
                            <div className="tab">
                              <h2 className="title">240 + </h2>
                              <p className="description sal-animate">
                                Windows RDP Plans
                              </p>
                            </div>
                          </a>
                        </li>

                        <li className="col-lg-3 nav-item" role="presentation">
                          <a href="/cloud-vps" className="nav-link tab-button">
                            <div className="tab">
                              <h2 className="title">110 +</h2>
                              <p className="description sal-animate">
                                Cloud VPS Plans
                              </p>
                            </div>
                          </a>
                        </li>

                        <li className="col-lg-3 nav-item" role="presentation">
                          <a
                            href="/dedicated-server"
                            className="nav-link tab-button"
                          >
                            <div className="tab">
                              <h2 className="title">59 +</h2>
                              <p className="description sal-animate">
                                Dedicated Server Plans
                              </p>
                            </div>
                          </a>
                        </li>
                        <li className="col-lg-3 nav-item" role="presentation">
                          <a
                            href="/private_rdp"
                            className="nav-link tab-button"
                          >
                            <div className="tab">
                              <h2 className="title">114 +</h2>
                              <p className="description sal-animate">
                                Private RDP Plans
                              </p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div> */}
                </div>
              </div>
            </div>

            {/* <!-- Start CTA Style-one Area  --> */}
            <div className="rainbow-rn-cta mt-5">
              <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                  <div className="col-lg-8">
                    <div className="inner">
                      <div className="content text-left">
                        <h4
                          className="title sal-animate"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Become a Reseller Today{" "}
                        </h4>
                        <p
                          className="sal-animate"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="300"
                        >
                          Partner with us and transform the way you do business.
                          As a reseller, you’ll gain access to top-tier
                          products, tailored support, and a platform to maximize
                          your success.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="right-content">
                      <div
                        className="call-to-btn text-start text-lg-end sal-animate"
                        data-sal="slide-up"
                        data-sal-duration="400"
                        data-sal-delay="400"
                      >
                        <div className="team-image">
                          <img
                            src={`${assets}/images/cta-img/team-01.png`}
                            alt="Group"
                          />
                        </div>
                        <a className="btn-default" href="/reseller-program">
                          Grow with DigiRDP{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="bg-shape">
                    <img
                      src={`${assets}/images/cta-img/bg-shape-01.png`}
                      alt="BG Shape"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* testimonial */}
            <div className="rainbow-testimonial-area rainbow-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="section-title text-left sal-animate"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="150"
                    >
                      <h4 className="subtitle">
                        <span className="theme-gradient">Testimonials</span>
                      </h4>
                      <h2 className="title mb--60">
                        The opinions of the community
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <Testimonial />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End Testimonial Area  --> */}

            {/* <!-- Start blog Area  --> */}
            <div className="rainbow-testimonial-area rainbow-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="section-title text-center sal-animate"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="150"
                    >
                      <h4 className="subtitle">
                        <span className="theme-gradient">Blogs</span>
                      </h4>
                      <h2 className="title mb--60">Explore Our Insights</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                      <BlogSlider />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div
                className="view-more-button text-center mt--45 sal-animate"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="400"
              >
                <a className="btn-default color-blacked" href="/blog">
                  View Blogs{" "}
                  <i className="fa-sharp fa-light fa-arrow-right ml--5"></i>
                </a>
              </div>
            </div>
            {/* <!-- End blog Area  --> */}
          </div>
        </div>
        {/* <!-- End Pricing Style-2  --> */}
      </div>
      {/* <!-- Start Pricing Area  --> */}

      <Location />
      <Footer />
    </Fragment>
  );
};

export default Cloudvps;
