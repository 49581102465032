import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const assets = '/assets';

function GlassPage() {
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        axios
            .get("https://admin.digirdp.chaipost.co.in/api/location")
            .then((response) => {
                setLocations(response.data);
            })
            .catch((error) => {
                console.error("Error fetching Dedicated data:", error);
            });
    }, []);


    return (
        <Fragment>
            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content main-content-new">
                <div className="rainbow-testimonial-area" style={{ position: "relative", zIndex: "0" }}>
                    <div className="video-background video-background-new">
                        <video autoPlay loop muted playsInline className="video">
                            <source src={`${assets}/images/bg-video/glass.mp4`} type="video/mp4" />
                        </video>
                        
                        <div className="container">
                            <div class="rbt-main-content rbt-main-content-new mb--0">
                                <div class="rbt-daynamic-page-content center-width">
                                    <div class="rbt-dashboard-content">
                                        <div class="banner-area">
                                            <div class="settings-area">
                                                <h3 class="title text-center">Looking Glass</h3>
                                            </div>
                                        </div>
                                        <div class="content-page pb--50">
                                            <div class="chat-box-list">
                                                <div class="single-settings-box profile-details-box overflow-hidden  overlay-new">
                                                    <div class="dc-list dc-list-new">
                                                        {locations.map((location, index)=>(
                                                            <Link to={`${location.url}`}>{location.name}</Link>
                                                        ))}
                                                        
                                                    </div>
                                                </div>
                                                <Link className="btn-new" to="/" style={{ color: "#000000", fontWeight: "500", Border: "1px solid #eee", background: "#c2d4ff" }}>
                                                    <span>Go Back <i className="fa-sharp fa-regular fa-arrow-right"></i></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          

            {/* <!-- Start Pricing Area  --> */}
            <div className="copyright-area copyright-style-one">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="copyright-right text-center text-lg-end">
                                <p className="copyright-text">Copyright © 2019-2024 <a href="/" className="btn-read-more"><span>DigiRDP </span></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
}
export default GlassPage;