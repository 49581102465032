import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import $ from 'jquery';
import Testimonial from "../slider/testimonial";
import BlogSlider from "../slider/BlogSlider";
import Location from "../sales-page/Location";
import { Helmet } from 'react-helmet';

// import WhyUs from "../slider/WhyUs";
import axios from "axios";
import { Link } from "react-router-dom"
const assets = '/assets';

const Rdp = () => {
    const [rdps, set_Rdps] = useState([]);
    useEffect(() => {
        axios
            .get("https://admin.digirdp.chaipost.co.in/api/windows_rdps")
            .then((response) => {
                set_Rdps(response.data[1]);
            })
            .catch((error) => {
                console.error("Error fetching RDP data:", error);
            });
    }, []);

    return (
        <Fragment>
             <Helmet>
        {/* Google Analytics Script */}
        

        <meta property="og:site_name" content="DigiRDP"/>
        <meta property="og:site" content="https://digirdp.com/rdp-plan/"/>
        <meta name="keywords" content="cheap RDP, buy cheap RDP, cheap RDP Online, Buy cheap RDP onlne, buy RDP, buy RDP online, buy shared RDP, shared RDP, buy admin RDP, admin RDP, cheap shared RDP, buy cheap shared RDP, cheap admin RDP, buy cheap admin RDP, USA RDP, buy USA RDP, UK RDP, buy UK RDP, cheap USA RDP, buy cheap USA RDP, buy cheap UK RDP, cheap UK RDP, cheap USA admin RDP, cheap UK admin RDP, france rdp, netherlands rdp, encoding rdp, rdp seller, buy rdp online with perfect money, buy rdp with paypal, buy rdp with bitcoins, buy rdp with credit card, buy RDP with Bitcoin"/>
        <meta property="og:title" content="DigiRDP -  Windows PLAN"/>
        <meta property="og:description" content="DigiRDP provides cheap Dedicated RDP SSD. Buy Cheap Dedicated RDP in USA, UK, Netherlands, France &amp; India location on affordable prices"/>
        <meta property="og:image" content=""/>
      </Helmet>
            <Header />


            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Category</h3>
                                    <p className="description b1">Explore our range of categories and choose the perfect plan tailored to your needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" >
                                            <h2 className="title w-600 mb--20">Windows RDP</h2>
                                            <p>Looking for RDP? You’re in the right place! We provide customized Windows RDP solutions to match your requirements, no matter the scale. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-wrapper ">
                                    <div className="has-show-more-inner-content">
                                        <div className="row row--15">
                                            {
                                                rdps.map((rdp, index) => (
                                                    <div className="col-lg-4 col-md-6 col-12 mt--30 sal-animate" data-sal="slide-up" data-sal-duration="700">
                                                        <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                                                            <Link to={`/rdp-plan/${rdp.url_text}`}>
                                                                <div className="inner inner-new">
                                                                    <div className="content">
                                                                        <div className="bottom-content">
                                                                           
                                                                            <div className="meta-info-section">
                                                                            <div className="desc-img">
                                                                                <img src={`${rdp.logo}`} alt="brang" style={{ borderRadius: '50px', width: '40px', height: '40px', }}/>
                                                                            </div>
                                                                        </div> 
                                                                       
                                                                        </div>
                                                                        <h5 className="pt-3">{rdp.name}</h5>
                                                                        <p className="m-0">{rdp.description}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))

                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* /* <!-- Start Features Area --> */} 
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Hosting Solutions </h2>
                                            <p>Explore our tailored solutions with Windows RDP, Cloud VPS, and Dedicated servers to find <br /> the perfect fit for your application deployment needs. <br /> Get the performance, reliability, and ease you deserve with our expertly crafted plans </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/rdp-plan" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/cloud-vps" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/dedicated-server" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/private_rdp" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>



                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Become a Reseller Today  </h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Partner with us and transform the way you do business. As a reseller, you’ll gain access to top-tier products, tailored support, and a platform to maximize your success. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/reseller-program">Grow with DigiRDP </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="view-more-button text-center mt--45 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                <a className="btn-default color-blacked" href="/blog">View Blogs <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}
                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}


            <Location />
            <Footer />

        </Fragment>
    );
}

export default Rdp;